// Copyright (C) 2023-2025 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import { omit } from '@modules/lodash';
import notification from '@modules/antd/lib/notification';
import { PluginEntryPoint, ComponentBuilder } from '@root/components/plugins-entrypoint';
import {
    BaseShapesAction, ShapeType, ObjectState, ObjectType,
} from '@root/cvat-core-wrapper';
import { ConvertMethod, WorkerAction } from './actions-worker';
import enableTrackerFeatures from './segment-anything-2/core-plugin';

const pluginName = 'Enhanced set of annotations actions';

const builder: ComponentBuilder = ({ core }) => {
    let worker: Worker | null = null;

    const converterFactory = (method: ConvertMethod) => {
        let shapeFrom: ShapeType = ShapeType.RECTANGLE;
        let shapeTo: ShapeType = ShapeType.RECTANGLE;

        if (method === ConvertMethod.MASK_TO_POLYGON) {
            shapeFrom = ShapeType.MASK;
            shapeTo = ShapeType.POLYGON;
        } else if (method === ConvertMethod.MASK_TO_RECT) {
            shapeFrom = ShapeType.MASK;
            shapeTo = ShapeType.RECTANGLE;
        } else if (method === ConvertMethod.POLYGON_TO_MASK) {
            shapeFrom = ShapeType.POLYGON;
            shapeTo = ShapeType.MASK;
        } else if (method === ConvertMethod.POLYGON_TO_RECT) {
            shapeFrom = ShapeType.POLYGON;
            shapeTo = ShapeType.RECTANGLE;
        } else if (method === ConvertMethod.RECT_TO_MASK) {
            shapeFrom = ShapeType.RECTANGLE;
            shapeTo = ShapeType.MASK;
        } else if (method === ConvertMethod.RECT_TO_POLYGON) {
            shapeFrom = ShapeType.RECTANGLE;
            shapeTo = ShapeType.POLYGON;
        }

        return class extends BaseShapesAction {
            private shownWarnings: boolean = false;

            public async init(): ReturnType<BaseShapesAction['init']> {
                return new Promise((resolve, reject) => {
                    this.shownWarnings = false;
                    worker = new Worker(new URL('./actions-worker.ts', import.meta.url));
                    worker.onmessage = (event: MessageEvent) => {
                        const result = event.data;
                        if (result.error) {
                            reject(new Error(result.error));
                        } else {
                            resolve();
                        }
                    };

                    worker.onerror = (event: ErrorEvent) => {
                        reject(new Error(event.message));
                    };

                    worker.postMessage({ command: WorkerAction.INITIALIZE });
                });
            }

            public async destroy(): ReturnType<BaseShapesAction['destroy']> {
                worker.terminate();
                worker = null;
            }

            public async run(
                input: Parameters<BaseShapesAction['run']>[0],
            ): ReturnType<BaseShapesAction['run']> {
                return new Promise((resolve, reject) => {
                    if (input.collection.shapes.length) {
                        worker.onmessage = (event: MessageEvent) => {
                            const result = event.data;
                            if (result.error) {
                                reject(new Error(result.error));
                            } else {
                                if (result.warnings.length) {
                                    console.warn(result.warnings.join('\n\n'));
                                    if (!this.shownWarnings) {
                                        this.shownWarnings = true;
                                        notification.warning({
                                            duration: 0,
                                            message: 'Warnings appeared during conversion',
                                            description: 'Please, open the browser console for details',
                                        });
                                    }
                                }

                                resolve({
                                    created: { shapes: result.shapes.map((shape) => omit(shape, 'id')) },
                                    deleted: { shapes: input.collection.shapes },
                                });
                            }
                        };

                        worker.onerror = (event: ErrorEvent) => {
                            reject(new Error(event.message));
                        };

                        worker.postMessage({
                            command: WorkerAction.RUN,
                            method,
                            shapes: input.collection.shapes,
                            height: input.frameData.height,
                            width: input.frameData.width,
                        });
                    } else {
                        resolve({
                            created: { shapes: [] },
                            deleted: { shapes: [] },
                        });
                    }
                });
            }

            public applyFilter(
                input: Parameters<BaseShapesAction['applyFilter']>[0],
            ): ReturnType<BaseShapesAction['applyFilter']> {
                const { collection } = input;
                return { shapes: collection.shapes.filter((shape) => shape.type === shapeFrom) };
            }

            public isApplicableForObject(objectState: ObjectState): boolean {
                return objectState.objectType === ObjectType.SHAPE && objectState.shapeType === shapeFrom;
            }

            public get name(): BaseShapesAction['name'] {
                return `Shapes converter: ${shapeFrom}s to ${shapeTo}s`;
            }

            public get parameters(): BaseShapesAction['parameters'] {
                return null;
            }
        };
    };

    core.actions.register(new (converterFactory(ConvertMethod.MASK_TO_POLYGON))());
    core.actions.register(new (converterFactory(ConvertMethod.MASK_TO_RECT))());
    core.actions.register(new (converterFactory(ConvertMethod.POLYGON_TO_MASK))());
    core.actions.register(new (converterFactory(ConvertMethod.POLYGON_TO_RECT))());
    core.actions.register(new (converterFactory(ConvertMethod.RECT_TO_MASK))());
    core.actions.register(new (converterFactory(ConvertMethod.RECT_TO_POLYGON))());

    enableTrackerFeatures(core);

    return {
        name: pluginName,
        destructor: () => {},
    };
};

function register(): void {
    if (Object.prototype.hasOwnProperty.call(window, 'cvatUI')) {
        (window as any as { cvatUI: { registerComponent: PluginEntryPoint } })
            .cvatUI.registerComponent(builder);
    }
}

window.addEventListener('plugins.ready', register, { once: true });
